import React from "react";
import Hero from "../Hero/Hero";

export default function Home() {
  return (
    <div className="text-white">
      <Hero />
    </div>
  );
}
